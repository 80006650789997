// convert Element to HTMLElement
const getHTMLElementOrNull = (maybeElement: unknown) =>
  maybeElement instanceof HTMLElement ? maybeElement : null

export function useFocusTrap(element: Ref<HTMLElement | undefined>) {
  const getFocusableElements = () => {
    if (!element.value) return { first: null, last: null }
    const focusableElements = [
      ...element.value.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      )
    ]
    const first = getHTMLElementOrNull(focusableElements[0])
    const last = getHTMLElementOrNull(focusableElements.at(-1))
    return { first, last }
  }

  const handleKeydown = (event: KeyboardEvent) => {
    if (!element.value || event.key !== 'Tab') return

    const { first, last } = getFocusableElements()

    if (event.shiftKey) {
      if (document.activeElement === first) {
        last?.focus()
        event.preventDefault()
      }
    } else if (document.activeElement === last) {
      first?.focus()
      event.preventDefault()
    }
  }

  const trapFocus = async () => {
    element.value?.addEventListener('keydown', handleKeydown)
    const { first } = getFocusableElements()
    first?.focus()
    await nextTick()
    first?.blur()
  }
  const releaseFocus = () =>
    element.value?.removeEventListener('keydown', handleKeydown)

  onUnmounted(releaseFocus)

  return {
    trapFocus,
    releaseFocus
  }
}
