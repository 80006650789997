export default function useBlockScroll(modalRef: Ref<HTMLElement | undefined>) {
  const scrollY = useState<number>('scrollY', () => 0)
  const blockingQueue = useState<Ref<HTMLElement | undefined>[]>(
    'scrollBlockingQueue',
    () => []
  )

  const stopPropagation = (e: Event) => {
    e.stopPropagation()
  }

  const blockScroll = () => {
    if (process.server) return
    scrollY.value = window.pageYOffset ?? window.scrollY

    document.body.style.setProperty('overflow', 'hidden')
    modalRef.value?.style.setProperty('overscroll-behavior', 'contain')
    modalRef.value?.addEventListener('pointermove', stopPropagation)

    blockingQueue.value.push(modalRef)
  }

  const unblockScroll = () => {
    if (process.server) return

    blockingQueue.value = blockingQueue.value.filter(
      blocker => modalRef !== blocker
    )
    if (blockingQueue.value.length > 0) return

    document.body.style.removeProperty('overflow')
    modalRef.value?.removeEventListener('pointermove', stopPropagation)

    // restore scroll position
    window.scrollTo({ top: scrollY.value, left: 0, behavior: 'instant' })
  }

  onUnmounted(() => {
    unblockScroll()
  })

  return {
    blockScroll,
    unblockScroll
  }
}
