export function useModal(modalRef: Ref<HTMLElement | undefined>) {
  const { blockScroll, unblockScroll } = useBlockScroll(modalRef)
  const { trapFocus, releaseFocus } = useFocusTrap(modalRef)

  const open = () => {
    blockScroll()
    trapFocus()
  }

  const close = () => {
    unblockScroll()
    releaseFocus()
  }

  return {
    open,
    close
  }
}
